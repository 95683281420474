<template>
  <FlexColContent :class="$style.p20">
    <div :class="$style.search">
      <TableHeader :formItems="formItems" @search="handleSearch">
        <template #extra-right>
          <a-button type="primary" @click="handleConfig">平面图配置</a-button>
          <a-button type="primary" @click="handleAddEdit({}, 'add')"
            >添加设备</a-button
          >
        </template>
      </TableHeader>
    </div>
    <template #footer>
      <PaginationTable
        ref="tableRef"
        :api="getDeviceList"
        :columns="columns"
        row-key="pkId"
        :search-params="form"
        :scroll="{ x: '100%', y: 'max-content' }"
      ></PaginationTable>
    </template>
  </FlexColContent>
</template>

<script>
import { Component, Vue, Provide } from 'vue-property-decorator';
import { pageTypeEnum } from '../component/safety-utils';
import FlexColContent from '@/components/flex-col-content/flex-col-content.vue';
import PaginationTable from '@/components/pagination-table';
import { Empty as EmptyObj } from 'ant-design-vue';
import {
  deviceList,
  projectList,
  // deviceStatusCount,
  deleteDeviceById,
} from '@/services/device-manage/device';
import OnlineOffline from '../tower-crane/components/online.vue';
import TableHeader from '@/components/pagination-table/components/table-header.vue';
import { deviceTypeEnum } from '@/enum/device';
import { createDrawer, createFormModal } from '@triascloud/x-components';
import AddEditDevice from '../component/add-edit-device.vue';
import Detail from './high-formwork-detail.vue';
import ConfigModal from './config.vue';
import { PublicSize } from '@/views/green-construction/components/utils';

@Component({
  components: {
    FlexColContent,
    PaginationTable,
    OnlineOffline,
    TableHeader,
    ConfigModal,
  },
})
export default class highFormworkIndex extends Vue {
  simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;

  @Provide('pageType')
  pageType = pageTypeEnum.highFormwork;
  deviceGroup = deviceTypeEnum[pageTypeEnum.highFormwork].deviceGroup;
  deviceType = deviceTypeEnum[pageTypeEnum.highFormwork].deviceType;

  form = {};

  // 设备列表分页表格数据
  async getDeviceList(data) {
    const res = await deviceList(
      {
        query: {
          current: data.current,
          size: data.size,
        },
        ...data,
      },
      this.deviceGroup,
    );
    return res;
  }

  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
        width: 180,
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.lastDateTime'),
        dataIndex: 'lastDataTime',
        width: 180,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('common.remark'),
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: this.$t('common.operation'),
        // fixed: 'right',
        key: 'action',
        width: 100,
        customRender: (_text, record) => {
          const actions = [
            {
              name: this.$t('common.edit'),
              handle: () => {
                this.handleAddEdit(record, 'edit');
              },
            },
            {
              name: this.$t('common.detail'),
              handle: () => {
                this.handleDetail(record);
              },
            },
            {
              name: this.$t('common.delete'),
              handle: () => {
                this.handleDelete(record, 'del');
              },
            },
          ];

          return (
            <div class={this.$style.action}>
              {actions.map(action => (
                <div onClick={action.handle}>{action.name}</div>
              ))}
            </div>
          );
        },
      },
    ];
  }

  async handleAddEdit(record, flag) {
    const res = await createFormModal(
      () => (
        <AddEditDevice
          editData={record}
          operationFlag={flag}
          pageType={this.pageType}
          projectList={this.projectList}
        />
      ),
      {
        width: PublicSize.FormModalSize,
        maskClosable: false,
        title: this.$t(
          { add: 'safety.addDevice', edit: 'safety.editDevice' }[flag],
        ),
      },
    );

    if (res) {
      this.$refs.tableRef.refresh();
    }
  }

  async handleDetail(record) {
    await createDrawer(
      () => <Detail detailData={record} pageType={this.pageType} />,
      {
        title: this.$t('safety.deviceDetail'),
        width: '750px',
        wrapClassName: 'deviceDetail',
      },
    );
  }

  async handleDelete(record) {
    const text = this.$t('safety.sureDeleteDevice');
    const tips = this.$t('safety.deleteDeviceTips');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('safety.importanceTips'),
          onOk: async () => {
            try {
              await deleteDeviceById(record.pkId, this.deviceGroup);
              const str = this.$t('safety.deleteSuccess');
              this.$message.success(str);
              this.$refs.tableRef.refresh();
            } catch {
              return false;
            }
          }, // 解绑 删除
        },
      );
    } catch {
      return false;
    }
  }

  async handleConfig() {
    await createFormModal(
      () => <ConfigModal deviceGroup={this.deviceGroup} />,
      {
        width: 970,
        maskClosable: false,
        title: '平面图配置',
      },
    );
  }

  handleSearch(form) {
    this.form = form;
    this.$refs.tableRef.search();
  }

  formItems = [];
  created() {
    this.formItems = [
      {
        props: 'device',
        formType: 'input',
        label: '设备',
        placeholder: '搜索设备名称/设备码',
      },
      {
        props: 'projectId',
        formType: 'select',
        label: '项目',
        placeholder: '全部项目',
        options: async () => {
          const res = (this.projectList = await projectList());
          return res.map(({ projectName, pkId }) => ({
            label: projectName,
            value: pkId,
          }));
        },
      },
    ];
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
.p20 {
  padding: 20px;
}

.search {
  padding-bottom: 8px;
  .online-offline_item {
    padding: none;
  }
}

.gap20 {
  gap: 20px;
}
.action {
  display: flex;
  gap: 20px;
  width: max-content;
  flex-wrap: nowrap;
  div {
    cursor: pointer;
    color: var(--primary);
  }
}
.success {
  color: var(--success);
}
.error {
  color: var(--delete);
}
</style>
