<template>
  <div :class="$style.listWrap">
    <ScreenSelect
      :type-options="typeOptions"
      @changeTimeRange="selectTime"
      @changeType="changeType"
      :showType="false"
      :placeholder="$t('safety.sensorType')"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: '100%', y: 'max-content' }"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import { deviceInfoHF, getHFUid } from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
      this.getUidList();
    }
  }

  mounted() {
    this.getTableList();
    this.getUidList();
  }

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.reportTime'),
        dataIndex: 'lastUpdateTime',
        customRender: txt => this.formateTime(txt),
        width: 180,
      },
      {
        align: 'left',
        title: this.$t('safety.weight') + '(kg)',
        dataIndex: 'weight',
        scopedSlots: { customRender: 'weight' },
        width: 100,
      },
      {
        align: 'left',
        title: '重量状态',
        dataIndex: 'stAlarmWei',
        width: 100,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '温度(℃)',
        dataIndex: 'temperature',
        scopedSlots: { customRender: 'weight' },
        width: 100,
      },
      {
        align: 'left',
        title: '温度状态',
        dataIndex: 'temperatureStatus',
        width: 100,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '垂直沉降(mm)',
        dataIndex: 'vert',
        width: 130,
      },
      {
        align: 'left',
        title: '垂直沉降状态',
        dataIndex: 'stAlarmCj',
        width: 120,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '水平位移(mm)',
        dataIndex: 'hor',
        width: 130,
      },
      {
        align: 'left',
        title: '水平位移状态',
        dataIndex: 'stAlarmSp',
        width: 120,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '立杆倾角(°)',
        dataIndex: 'ha',
        width: 120,
      },
      {
        align: 'left',
        title: '立杆倾角状态',
        dataIndex: 'stAlarmLgqj',
        width: 120,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
      {
        align: 'left',
        title: '立杆轴力(N)',
        dataIndex: 'axialForce',
        width: 120,
      },
      {
        align: 'left',
        title: '立杆轴力状态',
        dataIndex: 'axialForceStatus',
        width: 120,
        customRender: text => ['-', '正常', '报警'][text] ?? '-',
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  typeOptions = [];
  async getUidList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      deviceId: this.deviceId,
    };
    try {
      const res = await getHFUid(params, this.deviceGroup);
      this.typeOptions = res.map(v => ({
        label: v.text,
        value: v.value,
      }));
    } catch {
      return false;
    }
  }
  tableData = [];
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  selectType = '';
  changeType(v) {
    if (v && v !== 'all') {
      this.selectType = v;
    } else {
      this.selectType = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  changeMinusOne(val) {
    return val !== -1 ? val : '-';
  }
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType.value) {
      params.uid = this.selectType.value;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceInfoHF(
        params,
        this.deviceGroup,
      );
      this.tableData = records.map(v => ({
        ...v,
        weight: this.changeMinusOne(v.weight),
        vert: this.changeMinusOne(v.vert),
        hor: this.changeMinusOne(v.hor),
        ha: this.changeMinusOne(v.ha),
        stAlarmWei: this.changeMinusOne(v.stAlarmWei),
        stAlarmCj: this.changeMinusOne(v.stAlarmCj),
        stAlarmSp: this.changeMinusOne(v.stAlarmSp),
        stAlarmLggj: this.changeMinusOne(v.stAlarmLggj),
        sensor: '',
      }));
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style lang="less" module>
.listWrap {
  .normal {
    color: var(--success);
  }
  .warning {
    color: var(--warning);
  }
  .alarm {
    color: var(--delete);
  }
  .sensorDiv {
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sensor {
      height: 22px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
